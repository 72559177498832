import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { LoaderSpinner } from "../../loader/loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { confirmPayment, getLicenseList } from "../../../services/license";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/applicationCtx";
import { useState } from "react";
import { StatusCodes } from "http-status-codes";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../services/user-controller";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
// import { ButtonOutlined } from "../../../constants/constStyles";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const PurchaseRedirect = () => {
  // const navigate = useNavigate();
  useEffect(() => {
     setTimeout(() => {
      window.open(sessionStorage.getItem("redirect-url"), "_self");
    }, 3000);
    // return () => clearTimeout(timeout)
  }, []);
  return (
    <Typography padding={"0px"} display="flex" gap="12px" alignItems="center">
      {" "}
      {/* <ButtonOutlined
        onClick={() => navigate("/licence/purchasereview")}
        variant="outlined"
        startIcon={<ArrowBackIcon />}
      >
        {" "}
        Back
      </ButtonOutlined> */}
      Redirecting you to payment....
    </Typography>
  );
};

export default function PurchaseReview() {
  const {
    paymentIntent_CTX,
    setBuySnack,
    setLicenseList_CTX,
    setLicenseAl_CTX,
    setRenewSnack,
  } = useContext(ApplicationContext);
  const navigate = useNavigate();
  console.log(paymentIntent_CTX);
  const [loaderState, setLoaderState] = useState(true);
  const [payment, setPayment] = useState(false);
  useEffect(() => {
    getUserDetails()
      .then(function (apiData) {
        console.log(apiData);
        if (!apiData) {
          localStorage.clear();
          navigate("/login");
        }
        if (apiData.status === StatusCodes.FORBIDDEN) {
          localStorage.clear();
          navigate("/login");
        }
        if (apiData.status === StatusCodes.OK) {
          const payment = async () => {
            try {
              let response = await confirmPayment(sessionStorage.getItem("id"));
              if (response.status === StatusCodes.BAD_REQUEST) {
                setLoaderState(false);
                setPayment(true);
                setTimeout(() => {
                  sessionStorage.setItem("id", null);
                  navigate("/licence", { replace: true });
                }, 5000);
              }
              if (response.status === StatusCodes.OK) {
                setLoaderState(false);
                setPayment(false);
                setTimeout(() => {
                  sessionStorage.getItem("paymentType") === "buy"
                    ? setBuySnack(true)
                    : setRenewSnack(true);
                  getLicenseList()
                    .then(function (apiData) {
                      setLicenseList_CTX(apiData.data.licenseList);
                      setLicenseAl_CTX(apiData.data);
                    })
                    .catch(function (apiError) {
                      console.log(apiError);
                    });
                  // window.open('','_self')
                  // window.close()
                  sessionStorage.setItem("id", null);
                  sessionStorage.setItem("redirect-url", null);
                  navigate("/licence", { replace: true });
                }, 5000);
              }
              console.log(response, "paymentResponse");
            } catch (error) {
              console.log(error);
            }
          };
          payment();
        }
      })
      .catch(function (apiError) {
        console.log(apiError);
      });
  }, [
    navigate,
    setBuySnack,
    setLicenseAl_CTX,
    setLicenseList_CTX,
    setRenewSnack,
  ]);
  return (
    <>
      {loaderState ? (
        <LoaderSpinner />
      ) : (
        <>
          <Box
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "20px",
              flexDirection: "column",
            }}
          >
            {!payment ? (
              <Box
                style={{
                  width: "51%",
                  height: "38%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  flexDirection: "column",
                  border: "6px solid green",
                  borderRadius: "30px",
                  boxShadow: "0px 4px 23px 6px #c8bcbc",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection="column"
                  alignItems={"center"}
                >
                  <CheckCircleIcon sx={{ fontSize: "100px", color: "green" }} />
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "green",
                      textAlign: "center",
                    }}
                  >
                    Payment successful
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Redirecting you to license page...
                  </Typography>
                </Box>
                <CountdownCircleTimer
                  size={50}
                  strokeWidth={3}
                  isPlaying
                  duration={5}
                  colors={["#008000", "#008000", "#008000", "#008000"]}
                  colorsTime={[7, 5, 2, 0]}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </Box>
            ) : (
              <Box
                style={{
                  width: "51%",
                  height: "38%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "20px",
                  flexDirection: "column",
                  border: "6px solid red",
                  borderRadius: "30px",
                  boxShadow: "0px 4px 23px 6px #c8bcbc",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection="column"
                  alignItems={"center"}
                >
                  <CancelIcon sx={{ fontSize: "100px", color: "red" }} />
                  <Typography
                    sx={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "red",
                      textAlign: "center",
                    }}
                  >
                    Payment failed
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Redirecting you to license page...
                  </Typography>
                </Box>
                <CountdownCircleTimer
                  size={50}
                  strokeWidth={3}
                  isPlaying
                  duration={5}
                  colors={["#ff0000", "#ff0000", "#ff0000", "#ff0000"]}
                  colorsTime={[7, 5, 2, 0]}
                >
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
}

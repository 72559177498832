import { Box, Grow, Typography } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ModuleType } from "../../constants/application";
import { text_col } from "../../constants/constStyles";
//Navigation rail that appears for small and medium screens
const DrawerComp = ({ getActiveModule, setActiveModule, setLoaderState }) => {
  const [HBurger, setHBurger] = useState(false);
  const navigate = useNavigate();
  /* Production */
  const data = [
    {
      id: 1,
      label:!localStorage.getItem('super-key') ?  "License" : 'User',
    },
    {
      id: 2,
      label: "Payment Request",
    },
    {
      id: 3,
      label: "User Guide",
    },
    {
      id: 4,
      label: "Profile",
    },
    {
      id: 5,
      label: "Download",
    },
    {
      id: 6,
      label: "Logout",
    },
  ];

  const handleModule = (index) => {
    if (index === 2) {
      // setLoaderState(true);
      window.open("https://soliterata.com/docs/");
    } else if (index === 4) {
      // setLoaderState(true);
      window.open(
        // "https://github.com/Solitera-Software/SoliteraTA_AutoUpdate_Installer/releases/latest/download/SoliteraTA_Installer.exe",
        "https://soliterata.com/downloads/",
        "_blank"
      );
    } else if (index === 5) {
      window.localStorage.clear();
      localStorage.setItem("state", 0);
      setActiveModule(ModuleType.table);
      // setActiveModule(index);
      navigate("/login");
    } else {
      setActiveModule(index);
      setLoaderState(true);
      localStorage.setItem("state", index);
      setHBurger(false);
    }
  };

  /* Development */

  // const data = [
  //   {
  //     id: 1,
  //     label: 'Licence',

  //   },
  //   {
  //     id: 2,
  //     label: 'Payment Card',

  //     // icon: <PaymentIcon />
  //   },
  //   {
  //     id: 3,
  //     label: 'Tickets',

  //   },
  //   {
  //     id: 4,
  //     label: 'User Guide',

  //   },
  //   {
  //     id: 5,
  //     label: 'Profile',
  //   },
  //   {
  //     id: 6,
  //     label: 'Download'
  //   },
  //   {
  //     id: 7,
  //     label: 'Logout'
  //   },

  // ];

  // const handleModule = (index) => {
  //   if (index === 3) {
  //     setActiveModule(index);
  //     setLoaderState(true);
  //     window.open("https://soliterata.com/docs/")
  //   } else if (index === 6) {
  //     window.localStorage.clear();
  //     setActiveModule(index);
  //     navigate("/login");
  //   }else if(index===5){
  //     setActiveModule(index);
  //     setLoaderState(true);
  //  window.open("https://github.com/Solitera-Software/SoliteraTA_AutoUpdate_Installer/releases/latest/download/SoliteraTA_Installer.exe");
  //   } else {
  //     setActiveModule(index);
  //     setLoaderState(true);
  //     localStorage.setItem('state', index)
  //     setHBurger(false);
  //   }
  // };

  return (
    <>
      <Box
        display={"flex"}
        padding="6px 5px 6px 5px"
        zIndex="12"
        backgroundColor="#F9FAFA"
        boxShadow="4px -4px 5px 3px grey"
        alignItems="center"
        minWidth={"18vw"}
      >
        {/* Main LOGO - Start */}
        <a
          style={{ height: "35px" }}
          rel="noopener noreferrer"
          target={"_blank"}
          href="https://soliterata.com/"
        >
          <img
            width="35"
            height="35"
            style={{ borderRadius: "4px" }}
            src="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png"
            alt=""
            loading="lazy"
            srcSet="https://soliterata.com/wp-content/uploads/2022/06/final-150x150.png 150w, https://soliterata.com/wp-content/uploads/2022/06/final-300x300.png 300w, https://soliterata.com/wp-content/uploads/2022/06/final.png 500w"
            sizes="(max-width: 150px) 100vw, 150px"
          />
        </a>

        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            sx={{ fontSize: "19px", color: text_col.col_primary, fontWeight: "bold" }}
          >
            SoliteraTA
          </Typography>
        </Box>
        {!HBurger ? (
          <MenuIcon
            sx={{ fontSize: "33px", color: text_col.col_primary }}
            onClick={() => setHBurger(true)}
          />
        ) : (
          <CloseIcon
            sx={{ fontSize: "33px", color: text_col.col_primary }}
            onClick={() => setHBurger(false)}
          />
        )}
      </Box>
      {HBurger ? (
        <Grow in={true}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            zIndex="12"
            position="absolute"
            width="100%"
            height="45%"
            gap="1px"
          >
            {data.map((item, index) => (
              <Box
                display={"flex"}
                justifyContent="center"
                height="14%"
                alignItems="center"
                width="100%"
                color={getActiveModule === index ? "black" : "white"}
                backgroundColor={
                  getActiveModule === index ? "white" : text_col.col_primary
                }
                onClick={() => handleModule(index)}
              >
                <Typography sx={{ display: "flex" }}>{item.label}</Typography>
              </Box>
            ))}
          </Box>
        </Grow>
      ) : (
        ""
      )}
      {/* // NavigationRail End */}
    </>
  );
};

export default DrawerComp;

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  BootstrapDialog,
  ButtonOutlined,
  CssTextField,
  text_col,
} from "../../constants/constStyles";
import {
  approvePayments,
  getPaymentDetails,
} from "../../services/card-controller";
import CloseIcon from "@mui/icons-material/Close";
import { StatusCodes } from "http-status-codes";
import { useContext } from "react";
import { ApplicationContext } from "../../context/applicationCtx";
import { useNavigate } from "react-router-dom";
import { stringAvatar } from "../../utility/stringManipulation";
// import AddTaskIcon from "@mui/icons-material/AddTask";
import { getLicenseDetails, getLicenseList } from "../../services/license";
import { superAdminList } from "../../services/user-controller";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NoDataAvailable from "../no-data";
import { SearchIcon } from "../../constants/icons";
import { createRef } from "react";
import { ModuleType } from "../../constants/application";

export const LicenceInfo = ({
  filterUserDetail,
  validDate,
  details,
  tableType,
}) => {
  return (
    <>
      <Box display={"flex"} flexDirection="column" gap={"11px"}>
        {localStorage.getItem("super-key") ? (
          <Box display={"flex"} flexDirection="column">
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              Managed by
            </Typography>

            <Typography sx={{ fontSize: "13px" }}>
              Name: {filterUserDetail.managedByUser?.firstName ?? "---"}{" "}
              {filterUserDetail.managedByUser?.lastName ?? "---"}
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Email: {filterUserDetail.managedByUser?.email ?? "---"}
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>
              Organisation:{" "}
              {filterUserDetail.managedByUser?.organisationName ?? "---"}
            </Typography>
          </Box>
        ) : (
          ""
        )}

        <Box display={"flex"} flexDirection="column">
          <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
            License Info
          </Typography>

          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>Assigned To:</Typography>{" "}
            {!filterUserDetail.licenseDTO?.email
              ? "Not yet assigned"
              : filterUserDetail.licenseDTO.email}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography sx={{ fontSize: "13px" }}>License Type:</Typography>{" "}
              {filterUserDetail.licenseDTO?.licenseType ?? "---"}
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>No of Users:</Typography>{" "}
            {filterUserDetail.licenseDTO?.allowedUserCount ?? "---"}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>No of Workspace:</Typography>{" "}
            {filterUserDetail.licenseDTO?.allowedWorkspaceCount ?? "---"}
          </Typography>

          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "3px",
              minWidth: "260px",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}> Modules:</Typography>{" "}
            {filterUserDetail.licenseDTO?.webModule ? (
              <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
            ) : (
              <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
            )}
            Web ,
            {filterUserDetail.licenseDTO?.mobileModule ? (
              <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
            ) : (
              <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
            )}
            Mobile ,
            {filterUserDetail.licenseDTO?.desktopModule ? (
              <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
            ) : (
              <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
            )}
            Desktop
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>License No:</Typography>{" "}
            {filterUserDetail.licenseDTO?.licenseNo ?? "---"}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              display: "flex",
              alignItems: "center",
              gap: "3px",
            }}
          >
            <Typography sx={{ fontSize: "13px" }}> Valid Upto:</Typography>{" "}
            {filterUserDetail.licenseDTO?.validTill !== null ? (
              <Typography sx={{ fontSize: "13px" }}>
                {validDate.getDate() ?? "---"}{" "}
                {validDate.toDateString().split(" ")[1] ?? "---"}{" "}
                {validDate.getFullYear()}
              </Typography>
            ) : (
              "---"
            )}
          </Typography>
        </Box>

        {tableType !== "licence" ? (
          <Box display={"flex"} flexDirection="column">
            <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
              Transaction Notes
            </Typography>

            <Typography sx={{ fontSize: "13px" }}>
              Customer Notes: {details?.customerNotes ?? "---"}
            </Typography>
            {localStorage.getItem("super-key") ? (
              <Typography sx={{ fontSize: "13px" }}>
                Admin Notes: {details?.soliteraNotes ?? "---"}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

const TableRowWithData = ({
  details,
  paymentDetials,
  setPaymentDetails,
  getLicenseList_CTX,
  results,
  index,
  heighlighter,
  linkRefs,
}) => {
    const {getLoaderStateSpinner} = useContext(ApplicationContext)
  const [getLoaderState, setLoaderState] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [amount, setAmount] = useState(details.paidAmount);
  const [action, setAction] = useState(details.status);
  const [error, setError] = useState("");
  const [approveBody] = useState({});
  const [openLicInfo, setOpenLicInfo] = useState(false);
  const [licenseDetail, setLicenseDetail] = useState(false);
  const [filterUserDetail, setFilterUserDetail] = useState({});
  const [openApproveSnack, setOpenApproveSnack] = useState(false);
  const [responseSnack, setResponseSnack] = useState("");
  const approveQuery = useMediaQuery("(max-width:1007px)");
  const { paymentScroller, setPaymentScroller } =
    useContext(ApplicationContext);
  let createdDate = new Date(details.createdAt);

  const handleApproveDailog = (details, cellStatus) => {
    setAction(details.status);
    setAmount(details.paidAmount);
    console.log(details);
    if (!localStorage.getItem("super-key")) {
      // handleLicenceInfo(details.licenseIds[0]);
      getPerLicenseUser(details.licenseIds[0], cellStatus);
    } else {
      getPerLicenseUser(details.licenseIds[0], cellStatus);
    }
    setOpenApprove(true);
    // setApproveBody({
    //   notes: notes,
    //   paidAmount: details.paidAmount,
    //   paymentIntentId: details.paymentIntentId,
    //   status: action,
    // });
  };
  useEffect(() => {
    if (!getLoaderStateSpinner) {
      if (
        details?.licenseIds?.includes(paymentScroller) &&
        paymentScroller !== ""
      ) {
        console.log(index, "getindex");
        if (index !== 0) {
          handleScroll(index);
        } else {
          setTimeout(() => {
            setPaymentScroller("");
          }, 1000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentScroller,getLoaderStateSpinner]);
  const handleScroll = (index) => {
    // console.log('hey')
    // setTimeout(() => {

    linkRefs[index - 1].current.scrollIntoView({ behavior: "smooth" });
    setTimeout(() => {
      setPaymentScroller("");
    }, 2000);
    // }, 1000);
  };

  const approval = async (status) => {
    setLoaderState(true);
    console.log(approveBody);
    // approveBody.notes = notes;
    // approveBody.status = action;
    try {
      let response = await approvePayments({
        notes: notes,
        paidAmount: localStorage.getItem("super-key")
          ? amount
          : details.paidAmount,
        paymentIntentId: details.paymentIntentId,
        status: !status ? (action === "Cancel" ? "Failed" : action) : status,
      });
      console.log(response, "approvedResponse");
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setLoaderState(false);
        setError("Something went wrong");
      }
      if (response.status === StatusCodes.OK) {
        setResponseSnack(response.data.message);
        setLoaderState(false);
        setOpenApprove(false);
        handleClose();
        setOpenApproveSnack(true);
        setTimeout(async () => {
          let listReload = await getPaymentDetails();
          console.log(listReload.data);
          setPaymentDetails(listReload.data);
        }, 1000);
      }
      if (response.status === StatusCodes.NOT_ACCEPTABLE) {
        setError(response.data.message);
        setLoaderState(false);
      }
      if (response.status === StatusCodes.BAD_REQUEST) {
        setError(response.data.message);
        setLoaderState(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleLicenceInfo = (details) => {
  //     console.log(details, "perdetail");
  //     console.log(getLicenseList_CTX);
  //     if (!localStorage.getItem("super-key")) {
  //       getLicenseList_CTX.map((licInfo, i) => {
  //         if (details?.includes(licInfo.licenseNo)) {
  //           //   console.log(licInfo, "afterFilter");
  //           setFilterUserDetail(licInfo);
  //         }
  //         return false;
  //       });
  //     } else {
  //       getLicenseList_CTX.map((licInfo, i) => {
  //         licInfo.licenseList.licenseList.map((subLic, subIndex) => {
  //           //   console.log(subLic, "BeforesubLic");
  //           console.log(subLic, "superLicense");

  //           return false;
  //         });
  //         return false;
  //       });
  //     }
  //   };

  //   const handleCell = (details) => {
  //     // setLicenseDetail(true);
  //     setOpenLicInfo(true);
  //     // handleLicenceInfo(details);
  //     getPerLicenseUser(details);
  //   };

  //   const handleConfirmInfo = (ids) => {
  //     // setLicenseDetail(true);
  //     handleLicenceInfo(ids);
  //   };

  const handleClose = () => {
    setOpenApprove(false);
    setLoaderState(false);
    setAmount("");
    setNotes("");
    setAction("");
    setError("");
  };

  const getPerLicenseUser = async (licenseNo, cellStatus) => {
    try {
      let response = await getLicenseDetails(licenseNo);
      console.log(response, "licencePerUser");
      if (response.status === StatusCodes.OK) {
        //           let aa = Object.entries(response.data?.managedByUser)
        //           aa.map((data,i)=>{
        //             console.log(data)
        //           })
        // console.log(aa,'aaa')
        setFilterUserDetail(response.data);
        if (cellStatus) {
          setOpenLicInfo(true);
        } else {
          // setLicenseDetail(true)
        }
      }
      if (response.status === StatusCodes.INTERNAL_SERVER_ERROR) {
        setResponseSnack("Something went wrong");
        setOpenApproveSnack(true);
      }
      if (response.status === StatusCodes.BAD_REQUEST) {
        setFilterUserDetail({});
        setResponseSnack(response.data.message);
        if (cellStatus) {
          setOpenApproveSnack(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   const getPerLicenseUser = async(licenseNo) =>{
  //     try{
  //     let response = await getLicenseDetails(licenseNo)
  //     console.log(response,'licencePerUser')
  //     setFilterUserDetail(response.data.licenseDTO)
  //     // setOpenLicInfo(true);
  //     setLicenseDetail(true)

  //     }
  //     catch(error){
  //   console.log(error)
  //     }
  //   }
  //   console.log(filterUserDetail, "filter");
  // details.licenseIds.splice(0, 0, "YWCFRL8PPL","YWCFRL8PPL","YWCFRL8PPL","YWCFRL8PPL");

  let validDate = new Date(filterUserDetail.licenseDTO?.validTill);
  //   let aa = Object.entries(filterUserDetail?.managedByUser)
  console.log(filterUserDetail, "aaa");
  //   objec
  return (
    <>
      <TableRow
        ref={linkRefs[index]}
        sx={{
          background:
            index === results?.length - 1 && heighlighter
              ? "orange !important"
              : details?.licenseIds?.includes(paymentScroller)
              ? "orange !important"
              : "none",
        }}
      >
        <TableCell  
        // onClick={()=>{handleScroll(4)}}
        >
          {" "}
          {details?.paymentGateway === "MANUAL"
            ? "OFFLINE"
            : details?.paymentGateway}
        </TableCell>
        <TableCell>
          <Typography
            sx={{
              "&:hover": { cursor: "pointer" },
              //   display: "flex",
              //   flexWrap: "wrap",
              //   width:'40%'
            }}
          >
            {/* <Tooltip
              arrow
              title={details?.licenseIds.map((data, i) => {
                return (
                  <Typography>
                    {data}
                    {details?.licenseIds.length > 0
                      ? i !== details?.licenseIds.length - 1
                        ? ","
                        : ""
                      : ""}
                  </Typography>
                );
              })}
            ></Tooltip> */}

            {/* <CssTextField  rows={details.licenseIds.length === 1 ? 1 : 2}  multiline size="small" disabled sx={{'.MuiOutlinedInput-input:hover':{cursor: 'pointer',textDecoration:'underline'   }, "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
        },}} fullWidth value={details?.licenseIds.map((ids, i) => {
                  return ids
                  
                  
                })} variant='outlined' /> */}
            <Typography
              sx={{
                maxHeight: "84px",

                overflow: "auto",
              }}
            >
              {details?.licenseIds?.map((ids, i) => {
                return (
                  <Tooltip
                    arrow
                    placement="right-start"
                    title={
                      <Typography>Click to see license details</Typography>
                    }
                  >
                    <Typography
                      onClick={() => {
                        getPerLicenseUser(ids, true);
                      }}
                      sx={{
                        "&:hover": { textDecoration: "underline" },
                        width: "fit-content",
                        color: text_col.col_primary,
                      }}
                    >
                      {ids}
                      {details?.licenseIds.length > 0
                        ? i !== details?.licenseIds.length - 1
                          ? ","
                          : ""
                        : ""}
                    </Typography>
                  </Tooltip>
                );
              })}
            </Typography>
            {/* <Typography>
                {details?.licenseIds[0]}
                {details?.licenseIds.length !== 1 ? "...." : ""}
              </Typography> */}
          </Typography>
        </TableCell>
        <TableCell>&pound;{details?.paidAmount}</TableCell>
        <TableCell>{details?.status}</TableCell>
        <TableCell>
          {createdDate.getDate()} {createdDate.toDateString().split(" ")[1]}{" "}
          {createdDate.getFullYear()}
        </TableCell>
        <TableCell align="center">
          <Typography sx={{ display: "flex", justifyContent: "center" }}>
            <Tooltip
              title={
                <Typography sx={{ fontSize: "12px" }}>
                  {details.status === "Under_Process" &&
                  !localStorage.getItem("super-key")
                    ? "Verification is in progress"
                    : details.status === "Paid"
                    ? "Already paid"
                    : details.status === "Failed"
                    ? "Payment failed"
                    : "Confirm payment"}
                </Typography>
              }
              arrow
            >
              <Typography
                sx={{
                  cursor:
                    details.status === "Paid" ||
                    details.status === "Under_Process" ||
                    details.status === "Failed"
                      ? "default"
                      : "pointer",
                }}
                // onClick={handleOpenRenew}
              >
                {/* <Icon /> */}
                {/* <AddTaskIcon sx={{ color: "green" }} /> */}
                <ButtonOutlined
                  onClick={() => handleApproveDailog(details, false)}
                  disabled={
                    localStorage.getItem("super-key")
                      ? false
                      : details.status === "Under_Process" ||
                        details.status === "Paid" ||
                        details.status === "Failed"
                      ? true
                      : false
                  }
                  variant="outlined"
                >
                  {details.status === "Under_Process" &&
                  !localStorage.getItem("super-key")
                    ? "In-progress."
                    : details.status === "Failed"
                    ? "failed"
                    : "Confirm"}
                </ButtonOutlined>
              </Typography>
            </Tooltip>
          </Typography>
        </TableCell>
      </TableRow>
      <Box>
        <BootstrapDialog
          open={openApprove}
          onClose={() => {
            handleClose();
            setTimeout(() => {
              setLicenseDetail(false);
            }, 300);
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "360px",
              maxHeight: "100%",
              // height: "73%",
              width: "44%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              padding: "4% 0% 4%",
              gap: "7px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "93%",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {!licenseDetail ? (
                <></>
              ) : (
                <Typography
                  onClick={() => setLicenseDetail(false)}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ArrowBackIcon
                    sx={{
                      color: text_col.col_primary,
                      fontSize: "28px",
                      cursor: "pointer",
                    }}
                  />
                </Typography>
              )}
              <Typography sx={{ fontSize: "19px" }}>
                {!licenseDetail ? "Confirm Payment" : "License Info"}
              </Typography>
              <CloseIcon
                onClick={() => {
                  handleClose();
                }}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ textAlign: "center", color: "red" }}>
                {error}
              </Typography>
            </Box>
            {!licenseDetail ? (
              <>
                {" "}
                <Box
                  display={"flex"}
                  width="100%"
                  gap={approveQuery ? "28px" : "0px"}
                  flexDirection={approveQuery ? "column" : "row"}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      borderRight: "1px solid #E8E9EB",
                    }}
                  >
                    <Box width={"18px"}></Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "86%",
                        gap: "2px",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
                        Please confirm to approve the payment :
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "13px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Mode:{" "}
                            {details?.paymentGateway === "MANUAL"
                              ? "OFFLINE"
                              : details?.paymentGateway}
                          </Typography>{" "}
                          {/* {license.licenseType} */}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "13px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Amount: &pound;{details?.paidAmount}
                          </Typography>{" "}
                          {/* {license.licenseType} */}
                        </Typography>
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: "13px",
                          display: "flex",
                          alignItems: "center",
                          // gap: "4px",
                        }}
                      >
                        <Typography sx={{ fontSize: "13px" }}>
                          {" "}
                          Created At
                        </Typography>{" "}
                        : {createdDate.getDate()}{" "}
                        {createdDate.toDateString().split(" ")[1]}{" "}
                        {createdDate.getFullYear()}
                      </Typography>

                      <Typography
                        //   onClick={() => setLicenseDetail(true)}
                        sx={{
                          fontSize: "13px",
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "4px",
                          cursor: "pointer",
                          // borderBottom: " 1px solid #d5d5d5",
                          // borderTop: " 1px solid #d5d5d5",
                          // marginTop: "7px",
                        }}
                      >
                        <Typography sx={{ fontSize: "13px" }}>
                          {" "}
                          License Info:
                        </Typography>
                        <Tooltip
                          arrow
                          title={
                            //   <Typography>
                            //     {details?.licenseIds
                            //       .map((ids, i) => {
                            //         return ids;
                            //       })
                            //       .join(",")}
                            //   </Typography>
                            <Typography>
                              click to see license details
                            </Typography>
                          }
                        >
                          <Typography
                            id="tableScrollPayment"
                            sx={{
                              display: "flex",
                              maxWidth: "72%",
                              color: text_col.col_primary,
                              alignItems: "center",
                              fontSize: "13px",
                              flexWrap: "wrap",
                              maxHeight: "89px",
                              overflow: "auto",
                              //   borderBottom: "1px dotted #8C57F2",
                            }}
                          >
                            {details?.licenseIds.map((ids, i) => {
                              return (
                                <Typography
                                  onClick={() => {
                                    getPerLicenseUser(ids, false);
                                  }}
                                  sx={{
                                    "&:hover": { textDecoration: "underline" },
                                  }}
                                >
                                  {ids}
                                  {details?.licenseIds.length > 0
                                    ? i !== details?.licenseIds.length - 1
                                      ? ","
                                      : ""
                                    : ""}
                                </Typography>
                              );
                            })}
                          </Typography>
                        </Tooltip>
                        {/* <ArrowForwardIcon
                        sx={{
                          color: text_col.col_primary,
                          fontSize: "23px",
                          cursor: "pointer",
                        }}
                      /> */}
                      </Typography>

                      <Box
                        sx={{
                          paddingTop: "3%",
                          display: "flex",
                          flexDirection: "column",
                          gap: "14px",
                        }}
                      >
                        <FormControl fullWidth required>
                          {localStorage.getItem("super-key") ? (
                            <>
                              <InputLabel id="card">Payment Status</InputLabel>
                              <Select
                                value={action}
                                onChange={(e) => setAction(e.target.value)}
                                label="Payment Status"
                                fullWidth
                              >
                                {Array.of(
                                  "Paid",
                                  "Cancel",
                                  "Require_Payment",
                                  "Under_Process"
                                ).map((action, i) => {
                                  return (
                                    <MenuItem
                                      disabled={
                                        action === "Under_Process"
                                          ? true
                                          : false
                                      }
                                      value={action}
                                    >
                                      {action}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </>
                          ) : (
                            //   <Select
                            //   value={action}
                            //     onChange={(e) => setAction(e.target.value)}
                            //     label="Payment Status"
                            //     fullWidth
                            //   >
                            //     {Array.of("Paid", "Cancel").map((action, i) => {
                            //       return (
                            //         <MenuItem value={action}>{action}</MenuItem>
                            //       );
                            //     })}
                            //   </Select>
                            <></>
                          )}
                        </FormControl>
                        {localStorage.getItem("super-key") ? (
                          <CssTextField
                            placeholder="Amount"
                            required
                            type={"number"}
                            label="Amount"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  &pound;
                                </InputAdornment>
                              ),
                            }}
                            value={amount < 0 ? 0 : amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                              console.log(e.target.value);
                            }}
                            fullWidth
                          />
                        ) : (
                          <></>
                        )}
                        {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
                
                        <CssTextField
                          variant="outlined"
                          // label='Customer Info'
                          // InputLabelProps={{style:{color:'black',opacity:0.4}}}
                          sx={{ pointerEvents: "none" }}
                          // disabled
                          label={
                            !localStorage.getItem("super-key")
                              ? "Admin Notes"
                              : "Customer Notes"
                          }
                          rows="3"
                          value={
                            localStorage.getItem("super-key")
                              ? details.customerNotes
                              : details.soliteraNotes
                          }
                          placeholder={
                            !localStorage.getItem("super-key")
                              ? "Admin Info"
                              : "Customer Info"
                          }
                          multiline
                          maxRows={3}
                          onChange={(e) => {
                            setNotes(e.target.value);
                            console.log(e.target.value);
                          }}
                          fullWidth
                        />
                      </Box> */}
                        <CssTextField
                          rows="6"
                          label="Provide your transaction details"
                          // placeholder="Transaction Info"
                          required
                          multiline
                          maxRows={23}
                          onChange={(e) => {
                            setNotes(e.target.value);
                            console.log(e.target.value);
                          }}
                          fullWidth
                          variant="outlined"
                        />
                        <Box
                          sx={{
                            width: "100%",
                            height: "40%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "3%",
                          }}
                        >
                          {localStorage.getItem("super-key") ? (
                            <ButtonOutlined
                              disabled={
                                !notes ||
                                !action ||
                                action === "Under_Process" ||
                                !amount ||
                                amount === "0"
                                  ? true
                                  : getLoaderState
                                  ? true
                                  : false
                              }
                              variant="outlined"
                              sx={{ width: "130px" }}
                              onClick={() => approval()}
                            >
                              CONFIRM{" "}
                              {getLoaderState ? (
                                <CircularProgress
                                  size="1.2em"
                                  sx={{
                                    position: "absolute",
                                    right: "0",
                                    mr: 1,
                                    color: text_col.col_primary,
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </ButtonOutlined>
                          ) : (
                            <>
                              <ButtonOutlined
                                disabled={
                                  !notes || getLoaderState ? true : false
                                }
                                variant="outlined"
                                sx={{ width: "50%" }}
                                onClick={() => {
                                  setAction("");
                                  approval("Paid");
                                }}
                              >
                                Mark as paid{" "}
                              </ButtonOutlined>
                              <Button
                                disabled={
                                  !notes || getLoaderState ? true : false
                                }
                                variant="outlined"
                                color="error"
                                sx={{ width: "50%" }}
                                onClick={() => {
                                  setAction("");
                                  approval("Failed");
                                }}
                              >
                                Mark as Cancel{" "}
                              </Button>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    {/* <Box width={"18px"}></Box> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "86%",
                      gap: "11px",
                      alignItems: "center",
                      //   padding: "0px 23px 0px 23px",
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection="column"
                      gap="11px"
                      //   flex={1}
                      width={"85%"}
                      height="100%"
                    >
                      {filterUserDetail.licenseDTO ? (
                        <>
                          <LicenceInfo
                            filterUserDetail={filterUserDetail}
                            validDate={validDate}
                            details={details}
                            tableType={"payment"}
                          />
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {!approveQuery ? (
                            <Typography
                              sx={{
                                padding: "12px",
                                background: "#eff4fdbf",
                                borderRadius: "12px",
                              }}
                            >
                              License details not found
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "86%",
                    gap: "2px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      Assigned To:
                    </Typography>{" "}
                    {!filterUserDetail.email
                      ? "Not yet assigned"
                      : filterUserDetail.email}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px" }}>
                        License Type:
                      </Typography>{" "}
                      {filterUserDetail.licenseType ?? "---"}
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}> Modules:</Typography>{" "}
                    {filterUserDetail.webModule ? (
                      <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                    ) : (
                      <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                    )}
                    Web ,
                    {filterUserDetail.mobileModule ? (
                      <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                    ) : (
                      <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                    )}
                    Mobile ,
                    {filterUserDetail.desktopModule ? (
                      <CheckIcon sx={{ color: "green", fontSize: "15px" }} />
                    ) : (
                      <ClearIcon sx={{ color: "red", fontSize: "15px" }} />
                    )}
                    Desktop
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      License No:
                    </Typography>{" "}
                    {filterUserDetail.licenseDTO?.licenseNo ?? "---"}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      // gap: "4px",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px" }}>
                      {" "}
                      Valid Upto
                    </Typography>{" "}
                    :{" "}
                    <Typography sx={{ fontSize: "13px" }}>
                      {validDate.getDate() ?? "---"}{" "}
                      {validDate.toDateString().split(" ")[1] ?? "---"}{" "}
                      {validDate.getFullYear()}
                    </Typography>
                  </Typography>
                </Box>
              </>
            )}
            <Box sx={{ height: "4%" }}></Box>
          </Box>
        </BootstrapDialog>
      </Box>

      <Box>
        <BootstrapDialog
          open={openLicInfo}
          onClose={() => {
            setOpenLicInfo(false);
            setLoaderState(false);
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "360px",
              maxHeight: "100%",
              // height: "73%",
              width: "22%",
            },
          }}
        >
          <Box
            sx={{
              background: "white",
              // width: "100%",
              height: "20%",
              padding: "4% 0% 4%",
              gap: "7px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "20%",
                width: "91%",
                display: "flex",
                alignItems: "center",
                paddingLeft: "8px",
                gap: "4px",
              }}
            >
              <Typography sx={{ fontSize: "19px" }}>
                {"License Details"}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setOpenLicInfo(false);
                  setLoaderState(false);
                }}
                style={{
                  cursor: "pointer",
                  color: "grey",
                  position: "absolute",
                  right: "4%",
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ textAlign: "center", color: "red" }}>
                {error}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "86%",
                gap: "2px",
              }}
            >
              <LicenceInfo
                details={details}
                filterUserDetail={filterUserDetail}
                validDate={validDate}
                tableType={"payment"}
              />
            </Box>

            <Box sx={{ height: "4%" }}></Box>
          </Box>
        </BootstrapDialog>
      </Box>
      <Box>
        <BootstrapDialog
          open={openNotes}
          onClose={() => {
            setOpenNotes(false);
            setLoaderState(false);
          }}
          fullWidth
          maxWidth={"100%"}
          maxheight={"100%"}
          PaperProps={{
            style: {
              minWidth: "460px",
              maxHeight: "100%",
              // height: "73%",
              width: "22%",
            },
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", padding: "15px 19px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              Notes
            </Typography>
            <CloseIcon
              onClick={() => {
                setOpenNotes(false);
                setLoaderState(false);
              }}
              style={{
                cursor: "pointer",
                color: "grey",
                position: "absolute",
                right: "4%",
              }}
            />
          </Box>
          <Box
            sx={{
              background: "white",
              // width: "100%",
              width: "93%",
              minHeight: "13vh",
              padding: "28px 12px 28px",
              border: "3px solid #c9c9c9",
              alignSelf: "center",
              height: "20%",
              marginBottom: "22px",
              gap: "15px",
              display: "flex",
              flexDirection: !localStorage.getItem("super-key")
                ? "column-reverse"
                : "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "10px",
            }}
          >
            {!details.soliteraNotes && !details.customerNotes ? (
              <Typography>NO NOTES AVAILABLE</Typography>
            ) : (
              ""
            )}
            {details.soliteraNotes ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: !localStorage.getItem("super-key")
                    ? "row"
                    : "row-reverse",
                  maxWidth: "87%",
                  gap: "5px",
                  alignItems: "flex-end",
                  alignSelf: !localStorage.getItem("super-key")
                    ? "flex-start"
                    : "flex-end",
                }}
              >
                <Avatar
                  {...stringAvatar(`SS`)}
                  sx={{
                    height: "4vh",
                    width: "4vh",
                    fontSize: "10px",
                    background: "blue",
                  }}
                />{" "}
                <Box
                  sx={{
                    //   width:'40%',
                    //   height:'70px',
                    padding: "10px 25px 8px 14px",
                    display: "flex",
                    background: "#F4F7FC",

                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <Typography>{details?.soliteraNotes}</Typography>
                </Box>
              </Box>
            ) : (
              <></>
            )}
            {details.customerNotes ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: !localStorage.getItem("super-key")
                    ? "row"
                    : "row-reverse",
                  maxWidth: "87%",
                  gap: "5px",
                  alignItems: "flex-end",
                  alignSelf: !localStorage.getItem("super-key")
                    ? "flex-end"
                    : "flex-start",
                }}
              >
                {" "}
                <Box
                  sx={{
                    //   width:'40%',
                    //   height:'70px',
                    padding: "10px 25px 8px 14px",
                    display: "flex",
                    background: "#F4F7FC",

                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                  }}
                >
                  <Typography>{details?.customerNotes}</Typography>
                </Box>{" "}
                <Avatar
                  {...stringAvatar(`Rahul Kumar`)}
                  sx={{
                    height: "4vh",
                    width: "4vh",
                    fontSize: "10px",
                    background: "red",
                  }}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </BootstrapDialog>
      </Box>
      <Box>
        <Snackbar
          style={{ justifyContent: "center", width: "100%" }}
          open={openApproveSnack}
          onClose={() => setOpenApproveSnack(false)}
          message={responseSnack}
          autoHideDuration={3000}
          bodystyle={{ minWidth: 20 }}
        />
      </Box>
    </>
  );
};

export default function PaymentDetails() {
  const navigate = useNavigate();
  const { setLoaderStateSpinner,setActiveModule } = useContext(ApplicationContext);
  const [getLicenseList_CTX, setLicenseList_CTX] = useState([]);
  const [tabAdmin, setTabAdmin] = useState(0);
  const [heighlighter, setHighlighter] = useState(false);
  const [paymentDetials, setPaymentDetails] = useState({});
  const [search, setSearch] = useState("");
  let result;

  //   console.log(linkRefs);
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setLoaderStateSpinner(true);
    if (localStorage.getItem("super-key") === '') {
      navigate("/login");
      window.localStorage.clear();
      localStorage.setItem('state',0)
      setActiveModule(ModuleType.table);
    }
    const payment = async () => {
      try {
        let response = await getPaymentDetails();
        console.log(response.data);

        if (response.status === StatusCodes.OK) {
          setPaymentDetails(response.data);
          setLoaderStateSpinner(false);
        }
        if (response.status === StatusCodes.FORBIDDEN) {
          navigate("/login");
          window.localStorage.clear();
          localStorage.setItem('state',0)
          setActiveModule(ModuleType.table);
        }
      } catch (error) {
        console.log(error);
      }
    };
    payment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoaderStateSpinner, navigate]);

  //  paymentDetials.pendingPayments.length =

  useEffect(() => {
    // setLoaderStateSpinner(true);
    if (!localStorage.getItem("super-key")) {
      getLicenseList()
        .then(function (apiData) {
          //   if (!apiData) {
          //     navigate("/login");
          //   }
          //   if (apiData.status === StatusCodes.FORBIDDEN) {
          //     navigate("/login");
          //   }
          //   setLoaderStateSpinner(false);
          setLicenseList_CTX(apiData.data.licenseList);
          //   setLicenseAl_CTX(apiData.data);
        })
        .catch(function (apiError) {
          console.log(apiError);
        });
    } else {
      superAdminList()
        .then(function (response) {
          console.log(response.status, "axios");
          //   if (response.status === StatusCodes.FORBIDDEN) {
          //     navigate("/login");
          //   }
          if (response.status === StatusCodes.OK) {
            setLicenseList_CTX(response.data.userList);
            // setLicenseAl_CTX(response.data);
            // setLoaderState(false);
            // setLoaderStateSpinner(false);
          }
        })
        .catch(function (error) {
          console.log(error.response, "axioserror");
          //   if (error.response.status === StatusCodes.FORBIDDEN) {
          //     navigate("/login");
          //   }
        });
    }
  }, []);
  

  useEffect(() => {
    if (localStorage.getItem("scroller") === "true") {
      setTimeout(() => {
        setHighlighter(true);
        let ele = document.getElementById("tableScroll");
        ele.scrollTop = ele.scrollHeight;
        setTimeout(() => {
          localStorage.setItem("scroller", "false");
          setHighlighter(false);
        }, 1500);
        // window.scrollTo(0, 0)
      }, 800);
    }
  }, []);

  let clearSearch;
  const handleSearch = (e) => {
    clearTimeout(clearSearch);
    clearSearch = setTimeout(() => {
      setSearch(e.target.value?.toLowerCase());
    }, 500);
  };
  return (
    <>
      <Box width="100%">
        <Box
          display={"flex"}
          height="65px"
          backgroundColor="#F4F7FCBF"
          alignItems="center"
        >
          <Typography
            onClick={() => {}}
            variant="h3"
            component="h2"
            sx={{
              paddingLeft: "1%",
              fontWeight: "bold",
              color: "#393939",
              flex: 1,
              fontSize: "24px",
              whiteSpace: "nowrap",
            }}
          >
            PAYMENT REQUEST
          </Typography>
          <Box
            sx={{
              display: "flex",
              // width: "262px",
              height: "37px",
              gap: "6px",
              marginRight: "12px",
              marginLeft: "12px",
              alignItems: "center",
            }}
          >
            {/* <TextField
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="">
                    <SearchIcon sx={{ color: "green" }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              label="Search"
            />
            <ButtonMaterial
              className="buttonTxt"
              variant="contained"
              disableElevation
              sx={{
                // mt: 1,
                pr: 2,
                fontSize: tableQuery ? "8px" : "13px",
                fontWeight: "600",
                height: tableQuery ? "33px" : "auto",
                whiteSpace: "nowrap",
                // fontSize:'8px'
              }}
            >
              FREE LICENCE
            </ButtonMaterial>
            <ButtonMaterial
              className="buttonTxt"
              variant="contained"
              disableElevation
              sx={{
                // mt: 1,
                pr: 2,
                fontSize: tableQuery ? "8px" : "13px",
                fontWeight: "600",
                height: tableQuery ? "33px" : "auto",
                whiteSpace: "nowrap",
              }}
            >
              BUY LICENCE
            </ButtonMaterial> */}
          </Box>
          <Box
            flex={1}
            display="flex"
            justifyContent={"flex-end"}
            paddingRight="13px"
          >
            <TextField
              onChange={handleSearch}
              //   value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="">
                    <SearchIcon sx={{ color: "green" }} />
                  </InputAdornment>
                ),
              }}
              size="small"
              label="Search"
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          height="45px"
          backgroundColor="#F4F7FCBF"
          alignItems="center"
          paddingLeft={"14px"}
          gap="8px"
        >
          {localStorage.getItem("super-key") ? (
            Array.of("PENDING PAYMENTS", "REQUIRED VERIFICATION").map(
              (data, i) => {
                return (
                  <Typography
                    onClick={() => {
                      setSearch("");
                      setTabAdmin(i);
                    }}
                    sx={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      whiteSpace: "nowrap",
                      padding: "9px",
                      border:
                        tabAdmin === i ? `2px solid ${text_col.col_primary}`: "2px solid grey",
                      borderRadius: "5px",
                      lineHeight: 1,
                      color: tabAdmin === i ? text_col.col_primary : "#393939",
                      borderBottom:
                        tabAdmin === i ? `4px solid ${text_col.col_primary}` : "2px solid grey",
                      textDecorationColor: text_col.col_primary,
                      cursor: "pointer",
                      opacity: tabAdmin === i ? 1 : 0.4,
                    }}
                  >
                    {data}
                  </Typography>
                );
              }
            )
          ) : (
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontWeight: "bold",
                color: "#393939",
                fontSize: "17px",
              }}
            >
              ALL PAYMENTS
            </Typography>
          )}
        </Box>

        {localStorage.getItem("super-key") ? (
          <>
            <Box sx={{ width: "100%", display: "flex", height: "85vh" }}>
              {tabAdmin === 0 ? (
                <PendingPaymentsTable
                  heighlighter={heighlighter}
                  search={search}
                  result={result}
                  getLicenseList_CTX={getLicenseList_CTX}
                  paymentDetials={paymentDetials}
                  setPaymentDetails={setPaymentDetails}
                />
              ) : (
                <RequireVerificationTable
                  heighlighter={heighlighter}
                  search={search}
                  result={result}
                  getLicenseList_CTX={getLicenseList_CTX}
                  paymentDetials={paymentDetials}
                  setPaymentDetails={setPaymentDetails}
                />
              )}
            </Box>
          </>
        ) : (
          <>
            {" "}
            <AllPaymentsTable
              //   linkRef={linkRefs}
              heighlighter={heighlighter}
              search={search}
              result={result}
              getLicenseList_CTX={getLicenseList_CTX}
              paymentDetials={paymentDetials}
              setPaymentDetails={setPaymentDetails}
            />
          </>
        )}
      </Box>
    </>
  );
}

const AllPaymentsTable = ({
  paymentDetials,
  getLicenseList_CTX,
  setPaymentDetails,
  heighlighter,
  result,
  search,
  //   linkRefs,
}) => {
  //   const table = useRef();

  // useEffect(() => {
  //     if(localStorage.getItem('scroller') === 'true'){

  //         setTimeout(() => {
  //             setHighlighter(true)
  //             let ele = document.getElementById('tableScroll')
  //             ele.scrollTop = ele.scrollHeight
  //             setTimeout(() => {

  //                 // localStorage.setItem('scroller','false')
  //                 setHighlighter(false)
  //             }, 1000);
  //             // window.scrollTo(0, 0)
  //         }, 500);
  //     }
  //   }, [])

  //   const linkRefs = useRef(null)
  const linkRefs = Array.from(
    { length: paymentDetials?.allPayments?.length },
    () => createRef(null)
  );
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {/* <Box
          display={"flex"}
          height="35px"
          backgroundColor="#F4F7FCBF"
          alignItems="center"
          // justifyContent="center"
        >
          <Typography
            variant="h3"
            component="h2"
            sx={{
              paddingLeft: "1%",
              fontWeight: "bold",
              color: "#393939",
              fontSize: "17px",
            }}
          >
            ALL PAYMENTS
          </Typography>
        </Box> */}

        <TableContainer
          id="tableScroll"
          className="tableContainer"
          sx={{
            top: "88px",
            left: "5%",
            width: "100%",
            overflow: "auto",
            // height: tableQuery ? "77vh !important" : "86vh",
            height: "85vh",
            display: "flex",
            flexDirection: "column",
            borderCollapse: "collapse",
          }}
          component={Paper}
        >
          <Table
            sx={{ heigth: "12px", backgroundColor: "#F9FAFA" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  // onClick={handleScroll}
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  PAYMENT MODE
                </TableCell>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  LICENSE NO
                </TableCell>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  AMOUNT
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  align="left"
                >
                  STATUS
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  align="left"
                >
                  CREATED AT
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  colSpan="2"
                  align="center"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody ref={linkRefs} sx={{ backgroundColor: "white" }}>
              {paymentDetials?.allPayments
                ?.filter((filterSearch, i) => {
                  let date = new Date(filterSearch.createdAt)
                    .toDateString()
                    .toString();
                  if (search === "") {
                    return filterSearch;
                  }
                  if (
                    filterSearch.paymentGateway.toLowerCase().includes(search)
                  ) {
                    return filterSearch;
                  }
                  if (date.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                  if (filterSearch.status.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                  if (
                    filterSearch.licenseIds
                      .toString()
                      .includes(search.toUpperCase())
                  ) {
                    return filterSearch;
                  }
                  if (
                    filterSearch.paidAmount
                      .toString()
                      .toLowerCase()
                      .includes(search)
                  ) {
                    return filterSearch;
                  }
                  return false;
                })
                .map((details, index, results) => {
                  result = results.length;
                  return (
                    <TableRowWithData
                      linkRefs={linkRefs}
                      // renewSnack={renewSnack}
                      // setRenewSnack={setRenewSnack}
                      heighlighter={heighlighter}
                      results={results}
                      getLicenseList_CTX={getLicenseList_CTX}
                      paymentDetials={paymentDetials}
                      setPaymentDetails={setPaymentDetails}
                      key={index}
                      index={index}
                      details={details}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {paymentDetials?.allPayments?.length === 0 ||
          !paymentDetials?.allPayments ? (
            <NoDataAvailable message={"No payments available"} />
          ) : (
            ""
          )}
          {!result && paymentDetials?.allPayments ? (
            paymentDetials?.allPayments?.length !== 0 ? (
              <NoDataAvailable message={"No data found"} />
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </TableContainer>
      </Box>
    </>
  );
};

const PendingPaymentsTable = ({
  paymentDetials,
  search,
  getLicenseList_CTX,
  setPaymentDetails,
  heighlighter,
  result,
}) => {
  const linkRefs = Array.from(
    { length: paymentDetials?.allPayments?.length },
    () => createRef(null)
  );
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <TableContainer
          id="tableScrollPayment"
          className="tableContainer"
          sx={{
            top: "88px",
            left: "5%",
            width: "100%",
            overflow: "auto",
            // height: tableQuery ? "77vh !important" : "86vh",
            height: "91vh",
            display: "flex",
            flexDirection: "column",
            borderCollapse: "collapse",
          }}
          component={Paper}
        >
          <Table
            sx={{ heigth: "12px", backgroundColor: "#F9FAFA" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  PAYMENT MODE
                </TableCell>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  LICENSE NO
                </TableCell>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  AMOUNT
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  align="left"
                >
                  STATUS
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  align="left"
                >
                  CREATED AT
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  colSpan="2"
                  align="center"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "white" }}>
              {paymentDetials?.pendingPayments
                ?.filter((filterSearch, i) => {
                  let date = new Date(filterSearch.createdAt)
                    .toDateString()
                    .toString();
                  if (search === "") {
                    return filterSearch;
                  }
                  if (
                    filterSearch.paymentGateway.toLowerCase().includes(search)
                  ) {
                    return filterSearch;
                  }
                  if (date.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                  if (filterSearch.status.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                  if (
                    filterSearch.licenseIds
                      .toString()
                      .includes(search.toUpperCase())
                  ) {
                    return filterSearch;
                  }
                  if (
                    filterSearch.paidAmount
                      .toString()
                      .toLowerCase()
                      .includes(search)
                  ) {
                    return filterSearch;
                  }
                  return false;
                })
                .map((details, index, results) => {
                  result = results.length;
                  return (
                    <TableRowWithData
                      // renewSnack={renewSnack}
                      // setRenewSnack={setRenewSnack}
                      linkRefs={linkRefs}
                      results={results}
                      heighlighter={heighlighter}
                      getLicenseList_CTX={getLicenseList_CTX}
                      paymentDetials={paymentDetials}
                      setPaymentDetails={setPaymentDetails}
                      key={index}
                      index={index}
                      details={details}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {paymentDetials?.pendingPayments?.length === 0 ||
          !paymentDetials?.pendingPayments ? (
            <NoDataAvailable message={"No pending payments"} />
          ) : (
            ""
          )}
          {!result && paymentDetials?.pendingPayments ? (
            paymentDetials?.pendingPayments?.length !== 0 ? (
              <NoDataAvailable message={"No data found"} />
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </TableContainer>
      </Box>
    </>
  );
};

const RequireVerificationTable = ({
  paymentDetials,
  search,
  getLicenseList_CTX,
  setPaymentDetails,
  heighlighter,
  result,
}) => {
  const linkRefs = Array.from(
    { length: paymentDetials?.allPayments?.length },
    () => createRef(null)
  );
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <TableContainer
          id="tableScrollPayment"
          className="tableContainer"
          sx={{
            top: "88px",
            left: "5%",
            width: "100%",
            overflow: "auto",
            // height: tableQuery ? "77vh !important" : "86vh",
            height: "85vh",
            display: "flex",
            flexDirection: "column",
            borderCollapse: "collapse",
          }}
          component={Paper}
        >
          <Table
            sx={{ heigth: "12px", backgroundColor: "#F9FAFA" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  PAYMENT MODE
                </TableCell>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  LICENSE NO
                </TableCell>
                <TableCell
                  sx={{
                    color: " #171C26",
                    fontWeight: "600",
                  }}
                >
                  AMOUNT
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  align="left"
                >
                  STATUS
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  align="left"
                >
                  CREATED AT
                </TableCell>

                <TableCell
                  sx={{ color: " #171C26", fontWeight: "600" }}
                  colSpan="2"
                  align="center"
                >
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "white" }}>
              {paymentDetials?.requireVerification
                ?.filter((filterSearch, i) => {
                  let date = new Date(filterSearch.createdAt)
                    .toDateString()
                    .toString();
                  if (search === "") {
                    return filterSearch;
                  }
                  if (
                    filterSearch.paymentGateway.toLowerCase().includes(search)
                  ) {
                    return filterSearch;
                  }
                  if (date.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                  if (filterSearch.status.toLowerCase().includes(search)) {
                    return filterSearch;
                  }
                  if (
                    filterSearch.licenseIds
                      .toString()
                      .includes(search.toUpperCase())
                  ) {
                    return filterSearch;
                  }
                  if (
                    filterSearch.paidAmount
                      .toString()
                      .toLowerCase()
                      .includes(search)
                  ) {
                    return filterSearch;
                  }
                  return false;
                })
                .map((details, index, results) => {
                  result = results.length;
                  return (
                    <TableRowWithData
                      heighlighter={heighlighter}
                      // renewSnack={renewSnack}
                      // setRenewSnack={setRenewSnack}
                      linkRefs={linkRefs}
                      results={results}
                      getLicenseList_CTX={getLicenseList_CTX}
                      paymentDetials={paymentDetials}
                      setPaymentDetails={setPaymentDetails}
                      key={index}
                      index={index}
                      details={details}
                    />
                  );
                })}
            </TableBody>
          </Table>
          {paymentDetials?.requireVerification?.length === 0 ||
          !paymentDetials?.requireVerification ? (
            <NoDataAvailable message={"No require verifications"} />
          ) : (
            ""
          )}
          {!result && paymentDetials?.requireVerification ? (
            paymentDetials?.requireVerification?.length !== 0 ? (
              <NoDataAvailable message={"No data found"} />
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </TableContainer>
      </Box>
    </>
  );
};

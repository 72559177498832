import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useContext } from 'react';
import { ApplicationContext } from '../../context/applicationCtx';
import { LicenseTokenIcon } from '../../constants/icons';
import { useEffect } from 'react';
import { Button } from '@mui/material';
// import { useState } from 'react';



function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  let date = new Date(row.createdAt)
  useEffect(() => {
    document.body.classList.add('bodyShow')
  }, [])

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{row.firstName}</TableCell>
        <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{row.lastName}</TableCell>
        <TableCell component="th" scope="row">
          {row.email}
        </TableCell>
        <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{row.phoneNum}</TableCell>
        <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{row.status}</TableCell>
        <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{date.getDate()} - {date.getMonth() + 1} - {date.getFullYear()}</TableCell>

        {/* <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">action</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ "maxWidth": "113.2%", "width": "113.2%", "marginLeft": "-1.4%" }}>
              <Typography sx={{ "marginLeft": "1%", "fontSize": "20px" }}>LICENSE LIST OF {row.firstName.toUpperCase()}</Typography>
              <Box sx={{ display: 'flex', "gap": "3%", "paddingTop": "0.5%", "paddingLeft": "1%" }}>
                <Box sx={{ "display": "flex" }}>
                  <LicenseTokenIcon sx={{ "color": "blue" }} fontSize='large' />
                  <Typography mt={5} variant="h3" component="h2" sx={{ "fontSize": "15px", marginTop: '7px' }}>
                    ALLOCATED = {row.licenseList.allocated}
                  </Typography>


                </Box>
                <Box sx={{ "display": "flex" }}>
                  <LicenseTokenIcon sx={{}} fontSize='large' />
                  <Typography mt={5} variant="h3" component="h2" sx={{ "fontSize": "15px", marginTop: '8px' }}>
                    UNALLOCATED = {row.licenseList.unallocated}
                  </Typography>
                </Box>
                <Typography variant="h3" component="h2" sx={{ "fontSize": "15px", "left": "370px", marginTop: '0.5%' }}>
                  TOTAL = {row.licenseList.licenseList.length}
                </Typography>
              </Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                 
                <TableRow >
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D', width: '78px' }}>Token</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D', borderRight: '0', width: '271px' }} align="left">License Key&nbsp;</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Email&nbsp;</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D', width: '104px' }}>First Name</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D', width: '103px' }} align="left">Last Name</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Phone&nbsp;</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Expires On&nbsp;</TableCell>
              <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Status&nbsp;</TableCell>
            </TableRow>
                </TableHead>
                <TableBody>
                {row.licenseList.licenseList.map((data) => {
                    let finalKey = `${data.licenseKey}`
                    let date = new Date(data.validTill)
                    return(
                     <>
                      {data.firstName !== null ? <><TableRow>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="center"><LicenseTokenIcon sx={{ "color": "blue" }} fontSize='large' /></TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D',display:'flex',gap: "2%",alignItems: "center",padding:'3%' }} align="left">{finalKey.substring(0,19)}... <Button onClick={()=>navigator.clipboard.writeText(data.licenseKey)} variant="outlined">Copy</Button></TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{data.email}</TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{data.firstName}</TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{data.lastName}</TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{data.phoneNum}</TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{date.getDate()}-{date.getMonth() + 1}-{date.getFullYear()}</TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">{data.status}</TableCell>
                    </TableRow></> :  <><TableRow>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="center"><LicenseTokenIcon sx={{ "color": "black" }} fontSize='large' /></TableCell>
                      <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D',textAlign:'center'}} colSpan={7} align="left">Unallocated</TableCell>
                    </TableRow></>  }
                    </>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable() {
  const { getLicenseSup_CTX } = useContext(ApplicationContext);
  return (
    <TableContainer sx={{ width: '95%', marginLeft: '5%',maxHeight:740 }} component={Paper}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#7D7D7D',borderLeft:'2px solid #EFF0F0' }} align="left"></TableCell>
            <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D', width: '104px' }}>First Name</TableCell>
            <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D', width: '103px' }} align="left">Last Name</TableCell>
            <TableCell sx={{ borderRight: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Email&nbsp;</TableCell>
            <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Phone&nbsp;</TableCell>
            <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Status&nbsp;</TableCell>
            <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Created At&nbsp;</TableCell>

            {/* <TableCell sx={{ border: '1px solid #EFF0F0', color: '#7D7D7D' }} align="left">Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {getLicenseSup_CTX.map((row, index) => (
            <Row key={index} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}